import React, {PropsWithChildren, Suspense} from 'react'
import {StylesProvider} from './styles'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {MetaPixel} from './meta-analytics'
import {AuthenticationProvider} from '@distribrewtion-libs/authentication'
import {TranslationsProvider} from '@distribrewtion-libs/internationalization'
import {AnalyticsProvider} from './analytics'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {ChatProvider} from '@distribrewtion-libs/chat'

const queryClient = new QueryClient()

export const RootProviders = ({children}: PropsWithChildren) => (
  <Suspense>
    <TranslationsProvider>
      <QueryClientProvider client={queryClient}>
        <AuthenticationProvider>
          <ChatProvider showLauncher>
            <AnalyticsProvider>
              {children}
            </AnalyticsProvider>
          </ChatProvider>
        </AuthenticationProvider>
      </QueryClientProvider>
    </TranslationsProvider>
  </Suspense>
)

export const PageContainer = ({children}: PropsWithChildren) => (
  <>
    <ReactQueryDevtools/>
    <StylesProvider>
      {children}
    </StylesProvider>
    <MetaPixel/>
  </>
)
