import React, {PropsWithChildren} from 'react'
import {MetaAnalyticsProvider} from './meta-analytics'
import {AnalyticsProvider as GeneralAnalyticsProvider} from '@distribrewtion-libs/analytics'

export const AnalyticsProvider = ({children}: PropsWithChildren) => (
  <MetaAnalyticsProvider>
    <GeneralAnalyticsProvider>
      {children}
    </GeneralAnalyticsProvider>
  </MetaAnalyticsProvider>
)
