import {Script} from 'gatsby'
import {useEnvironmentConfigurationWithoutSuspense} from '@distribrewtion-libs/environment'
import React, {createContext, PropsWithChildren, useCallback} from 'react'
import {useSafeContext} from '@distribrewtion-libs/react'

declare const fbq: any

export enum MetaAnalyticsEvent {
  CONTACT_SALES_VIEW = 'ContactSalesView',
  CONTACT_SALES_SUBMIT = 'ContactSalesSubmit',
  CONTACT_SALES_CLOSE = 'ContactSalesClose',
}

type TrackMetaAnalyticsEvent = (event: MetaAnalyticsEvent) => void

type Context = TrackMetaAnalyticsEvent

const MetaAnalyticsContext = createContext<Context | null>(null)
MetaAnalyticsContext.displayName = 'MetaAnalyticsContext'

export const MetaAnalyticsProvider = ({children}: PropsWithChildren) => {
  const track = useCallback((event: MetaAnalyticsEvent) => {
    switch (event) {
    case MetaAnalyticsEvent.CONTACT_SALES_VIEW: {
      if (typeof fbq === 'function') {
        fbq('trackCustom', event, {})
      }
      break
    }
    case MetaAnalyticsEvent.CONTACT_SALES_SUBMIT: {
      if (typeof fbq === 'function') {
        fbq('trackCustom', event, {})
      }
      break
    }
    }
  }, [])

  return (
    <MetaAnalyticsContext.Provider value={track}>
      {children}
    </MetaAnalyticsContext.Provider>
  )
}

export const useTrackMetaAnalyticsEvent = () => {
  return useSafeContext(MetaAnalyticsContext)
}

const scriptBody = (id: string) => `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','https://connect.facebook.net/en_US/fbevents.js');
fbq.disablePushState = true;
fbq('init', '${id}');
fbq('track', 'PageView');`

export const MetaPixel = () => {
  const {data: environmentConfiguration} = useEnvironmentConfigurationWithoutSuspense()
  return environmentConfiguration && environmentConfiguration.keys.facebookPixelId
    ? <Script id="meta-pixel">{scriptBody(environmentConfiguration.keys.facebookPixelId)}</Script>
    : null
}
