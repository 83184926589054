import {createTheme, CssBaseline, ThemeProvider} from '@mui/material'
import React, {PropsWithChildren} from 'react'
import {SvgGlobalStyles, themeOptionsBase, themeOptionsLight} from '@distribrewtion-libs/theme'
import {merge} from 'lodash-es'

const theme = createTheme(merge({}, themeOptionsBase, themeOptionsLight))

export const StylesProvider = ({children}: PropsWithChildren) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme/>
        <SvgGlobalStyles/>
        {children}
      </ThemeProvider>
    </>
  )
}
