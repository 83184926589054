import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import React from 'react'
import {GatsbyBrowser} from 'gatsby'
import {PageContainer, RootProviders} from './src/providers/gatsby-wrappers'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) =>
  <RootProviders>{element}</RootProviders>

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({element}) =>
  <PageContainer>{element}</PageContainer>
